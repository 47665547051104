
import React from "react";

const Testinomial = () => {
  return (
    <>
{/*Start Testinomial*/}
<section className="testinomial-section bg-none pad-tb">
 <div className="container">
   <div className="row justify-content-center ">
     <div className="col-lg-8">
       <div className="common-heading">
         <span>What our clients say about DWS</span>
         <h2>Over 1200+ Satisfied Clients and Growing</h2>
       </div>
     </div>
   </div>
   <div className="row">
     <div className="col-lg-6">
       <div className="video-testimonials owl-carousel">
         <div className="video-review" data-tilt data-tilt-max={5} data-tilt-speed={1000}>
           <a className="video-link" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&rel=0">
             <img src="images/client/client-pic.jpg" alt="client" className="img-fluid" />
             <div className="review-vid-details">
               <div className="-vid-ico"><span className="triangle-play2" /></div>
               <p>Cina Cleaves</p>
             </div>
           </a>
         </div>
         <div className="video-review" data-tilt data-tilt-max={5} data-tilt-speed={1000}>
           <a className="video-link" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&rel=0">
             <img src="images/client/client-pic-a.jpg" alt="client" className="img-fluid" />
             <div className="review-vid-details">
               <div className="-vid-ico"><span className="triangle-play2" /></div>
               <p>Jokvch Marlin</p>
             </div>
           </a>
         </div>
         <div className="video-review" data-tilt data-tilt-max={5} data-tilt-speed={1000}>
           <a className="video-link" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&rel=0">
             <img src="images/client/client-pic.jpg" alt="client" className="img-fluid" />
             <div className="review-vid-details">
               <div className="-vid-ico"><span className="triangle-play2" /></div>
               <p>Cina Cleaves</p>
             </div>
           </a>
         </div>
         <div className="video-review" data-tilt data-tilt-max={5} data-tilt-speed={1000}>
           <a className="video-link" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&rel=0">
             <img src="images/client/client-pic-a.jpg" alt="client" className="img-fluid" />
             <div className="review-vid-details">
               <div className="-vid-ico"><span className="triangle-play2" /></div>
               <p>Jokvch Marlin</p>
             </div>
           </a>
         </div>
       </div>
     </div>
     <div className="col-lg-6">
       <div className="owl-carousel testimonial-card-a pl25">
         <div className="testimonial-card">
           <div className="t-text">
             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
           </div>
           <div className="client-thumbs mt30">
             <div className="media v-center">
               <div className="user-image bdr-radius"><img src="images/user-thumb/girl.jpg" alt="girl" className="img-fluid" /></div>
               <div className="media-body user-info v-center">
                 <h5>Mike Smith</h5>
                 <p>Business Owner, <small>Sydney, Australia</small></p>
               </div>
             </div>
           </div>
         </div>
         <div className="testimonial-card">
           <div className="t-text">
             <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
           </div>
           <div className="client-thumbs mt30">
             <div className="media v-center">
               <div className="user-image bdr-radius"><img src="images/user-thumb/girl.jpg" alt="girl" className="img-fluid" /></div>
               <div className="media-body user-info">
                 <h5>Fred Chener</h5>
                 <p>CEO of Aramxx, <small>Ohio, US State</small></p>
               </div>
             </div>
           </div>
         </div>
         <div className="testimonial-card">
           <div className="t-text">
             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
           </div>
           <div className="client-thumbs mt30">
             <div className="media v-center">
               <div className="user-image bdr-radius"><img src="images/user-thumb/girl.jpg" alt="girl" className="img-fluid" /></div>
               <div className="media-body user-info">
                 <h5>Mathilda Burns</h5>
                 <p>Backery Shop, <small>Tronto, Italy</small></p>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
</section>
{/*End Testinomial*/}
      
    </>
  );
};

export default Testinomial; 
 
 