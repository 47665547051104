import React from "react";


const NoPage = () => {
  return (
    <>

      
    </>
  );
};

export default NoPage;

